import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ToastComponent } from './components/toast/toast.component';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from '@services/toast.service';

@Component({
  selector: 'tech-list-toast',
  standalone: true,
  imports: [CommonModule, ToastComponent],
  templateUrl: './list-toast.component.html',
  styleUrl: './list-toast.component.scss',
})
export class ListToastComponent implements OnInit, OnDestroy {
  notifications: {
    id?: string;
    type: 'success' | 'error' | 'warning' | 'info';
    title?: string;
    msg?: string;
    duration?: number;
  }[] = [];

  private _unSuscribeAll: Subject<boolean> = new Subject<boolean>();
  private toastSrc: ToastService = inject(ToastService);

  ngOnInit(): void {
    this.toastSrc.$notifications
      .pipe(takeUntil(this._unSuscribeAll))
      .subscribe((notifications) => {
        this.notifications = notifications;
      });
  }
  ngOnDestroy(): void {
    this._unSuscribeAll.next(true);
    this._unSuscribeAll.complete();
  }

  removeNotification(id) {
    this.toastSrc.removeNotification(id);
  }
}
