import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { LoadingService } from '@services/loading.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'tech-loading-global',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loading-global.component.html',
  styleUrl: './loading-global.component.scss',
})
export class LoadingGlobalComponent implements OnInit, OnDestroy {
  public isVisible: boolean = false;
  private loadingSrc: LoadingService = inject(LoadingService);
  private _unSuscribeAll: Subject<boolean> = new Subject<boolean>();

  ngOnInit(): void {
    this.loadingSrc.loading
      .pipe(takeUntil(this._unSuscribeAll))
      .subscribe((loading) => {
        this.isVisible = loading;
      });
  }

  ngOnDestroy(): void {
    this._unSuscribeAll.next(true);
    this._unSuscribeAll.complete();
  }
}
