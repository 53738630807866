import { Route, Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { MainComponent } from './main/main.component';
import { LoginComponent } from './auth/pages/login/login.component';
import { RecoverPasswordComponent } from './auth/pages/recover-password/recover-password.component';
import { ChangePasswordComponent } from './auth/pages/change-password/change-password.component';
import { HomeComponent } from './main/pages/home/home.component';
import { withTokenGuard } from './shared/guards/with-token.guard';
import { withoutTokenGuard } from './shared/guards/without-token.guard';
import { CustomizeService } from '@services/customize.service';
import { natsConnectionResolver } from './shared/resolvers/nats-connection.resolver';

export const routes: Routes = [
  {
    path: CustomizeService.data.pages.auth.path,
    component: CustomizeService.data.pages.auth.component,
    canActivate: CustomizeService.data.pages.auth.canActivate,
    children: CustomizeService.data.pages.auth.children,
    data: CustomizeService.data.pages.auth?.data ?? {},
    resolve: {
      natsConnection: natsConnectionResolver,
    },
  },
  {
    path: CustomizeService.data.pages.dashboard.path,
    component: CustomizeService.data.pages.dashboard.component,
    canActivate: CustomizeService.data.pages.dashboard.canActivate,
    children: CustomizeService.data.pages.dashboard.children,
    data: CustomizeService.data.pages.dashboard?.data ?? {},
    resolve: {
      natsConnection: natsConnectionResolver,
    },
  },
  {
    path: CustomizeService.data.pages.all.path,
    redirectTo: CustomizeService.data.pages.all.redirectTo,
  },
];
