import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { CustomizeService } from '@services/customize.service';

export const natsConnectionResolver: ResolveFn<boolean> = (
  route,
  state,
  custimizeSrc: CustomizeService = inject(CustomizeService)
) => {
  return custimizeSrc
    .getConfig()
    .then(() => true)
    .catch(() => true);
};
