import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CustomizeService } from './shared/services/customize.service';
import { ListToastComponent } from './shared/components/list-toast/list-toast.component';
import { LoadingGlobalComponent } from './shared/components/loading-global/loading-global.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ListToastComponent, LoadingGlobalComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  private customizeSrc: CustomizeService = inject(CustomizeService);
  constructor() {
    // this.customizeSrc.getConfig();
  }
}
