import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'tech-toast',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
})
export class ToastComponent {
  @Input() config: {
    type: 'success' | 'error' | 'warning' | 'info';
    title?: string;
    msg?: string;
    duration?: number;
  } = {
    type: 'success',
    title: '',
    msg: '',
    duration: 3000,
  };
}
