<ul class="list-notification">
  @for (notification of notifications; track $index) {
  <li
    class="list-notification__item"
    (click)="removeNotification(notification.id)"
  >
    <tech-toast [config]="notification"></tech-toast>
  </li>
  }
</ul>
